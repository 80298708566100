import { defaults } from '../../index.js';
import { gsapConfig } from '../../index.js';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { gsap } from "gsap/dist/gsap";

window.addEventListener("DOMContentLoaded", () => {
 gsap.registerPlugin(ScrollTrigger);
 gsapConfig();
 animateServices();
});

function animateServices() {
 const accordions = document.querySelectorAll(".accordion");
 const panels = document.querySelectorAll(".panel");
 panels[0].style.maxHeight = panels[0].scrollHeight + "px";
 accordions.forEach(accordion => {
  accordion.addEventListener("click", function() {
   this.classList.toggle("active");
   const panel = this.nextElementSibling;
   if (panel.style.maxHeight) {
    panel.style.maxHeight = null;
   } else {
    panel.style.maxHeight = panel.scrollHeight + "px";
   }
  })
 });
 return;
}
